import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c401a55c = () => interopDefault(import('../pages/contact-for-schools.vue' /* webpackChunkName: "pages/contact-for-schools" */))
const _c1c3ce78 = () => interopDefault(import('../pages/locker.vue' /* webpackChunkName: "pages/locker" */))
const _566776e5 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _2ad8847c = () => interopDefault(import('../pages/unsubscribe-successful.vue' /* webpackChunkName: "pages/unsubscribe-successful" */))
const _589bae74 = () => interopDefault(import('../pages/payment-failed/_failed_payment_id.vue' /* webpackChunkName: "pages/payment-failed/_failed_payment_id" */))
const _951f6468 = () => interopDefault(import('../pages/payment-successful/_successful_payment_id.vue' /* webpackChunkName: "pages/payment-successful/_successful_payment_id" */))
const _5358d204 = () => interopDefault(import('../pages/registration-successful/_successful_registration_id.vue' /* webpackChunkName: "pages/registration-successful/_successful_registration_id" */))
const _ece65e60 = () => interopDefault(import('../pages/subscription-renewal/_subscription_renewal_id.vue' /* webpackChunkName: "pages/subscription-renewal/_subscription_renewal_id" */))
const _08f31e54 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0c6060e4 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact-for-schools",
    component: _c401a55c,
    name: "contact-for-schools___hu___default"
  }, {
    path: "/contact-for-schools",
    component: _c401a55c,
    name: "contact-for-schools___hu"
  }, {
    path: "/locker",
    component: _c1c3ce78,
    name: "locker___hu___default"
  }, {
    path: "/locker",
    component: _c1c3ce78,
    name: "locker___hu"
  }, {
    path: "/registration",
    component: _566776e5,
    name: "registration___hu___default"
  }, {
    path: "/registration",
    component: _566776e5,
    name: "registration___hu"
  }, {
    path: "/unsubscribe-successful",
    component: _2ad8847c,
    name: "unsubscribe-successful___hu___default"
  }, {
    path: "/unsubscribe-successful",
    component: _2ad8847c,
    name: "unsubscribe-successful___hu"
  }, {
    path: "/payment-failed/:failed_payment_id",
    component: _589bae74,
    name: "payment-failed-failed_payment_id___hu___default"
  }, {
    path: "/payment-failed/:failed_payment_id",
    component: _589bae74,
    name: "payment-failed-failed_payment_id___hu"
  }, {
    path: "/payment-successful/:successful_payment_id",
    component: _951f6468,
    name: "payment-successful-successful_payment_id___hu___default"
  }, {
    path: "/payment-successful/:successful_payment_id",
    component: _951f6468,
    name: "payment-successful-successful_payment_id___hu"
  }, {
    path: "/registration-successful/:successful_registration_id?",
    component: _5358d204,
    name: "registration-successful-successful_registration_id___hu___default"
  }, {
    path: "/registration-successful/:successful_registration_id?",
    component: _5358d204,
    name: "registration-successful-successful_registration_id___hu"
  }, {
    path: "/subscription-renewal/:subscription_renewal_id",
    component: _ece65e60,
    name: "subscription-renewal-subscription_renewal_id___hu___default"
  }, {
    path: "/subscription-renewal/:subscription_renewal_id",
    component: _ece65e60,
    name: "subscription-renewal-subscription_renewal_id___hu"
  }, {
    path: "/",
    component: _08f31e54,
    name: "index___hu___default"
  }, {
    path: "/",
    component: _08f31e54,
    name: "index___hu"
  }, {
    path: "/:slug",
    component: _0c6060e4,
    name: "slug___hu___default"
  }, {
    path: "/:slug",
    component: _0c6060e4,
    name: "slug___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
